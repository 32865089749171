<template>
  <div class="home">
    <!-- <h1>首页</h1> -->
    <div class="topNull"></div>
    <home-bar class="homebar"></home-bar>
    <router-view class="animate__animated animate__headShake"></router-view>
  </div>
</template>
<script>
import HomeBar from "@/components/HomeBar";
export default {
  components: {
    HomeBar,
  },
};
</script>
<style lang="less" scoped>
.home {
  // background-color: #d8ebe4;
  background-color: #ffffff;
  .topNull{
    height: 50px;
  }
  // .homebar{
  //   margin-top: 90px;
  // }
}
</style>