<template>
  <div class="playpage">
    <ol class="top">
      <li>
        <i class="iconfont icon-arrow-down-bold" @click="$emit('close')"></i>
      </li>
      <li>
        <p>{{ songs.name }}</p>
        <p>
          <span v-for="item in songs.ar" :key="item.id">
            {{ item.name }} -
          </span>
          <span v-for="(i, index) in songs.al" :key="index">
            {{ index == "name" ? i : "" }}
          </span>
        </p>
      </li>
      <li>
        <i class="iconfont icon-elipsis" @click="$emit('close')"></i>
      </li>
    </ol>
    <!-- 背景图 -->
    <div
      class="img"
      v-for="(pic, index) in songs.al"
      :key="index"
      v-show="index == 'picUrl'"
    >
      <img
        :class="[{ paus: !playstate }]"
        :src="index == 'picUrl' ? pic : ''"
      />
    </div>
    <!-- 歌词 -->
    <div class="lyricbox">
      <ul :style="{ transform: `translateY(-${lyricIndex * 31 - 150}px)` }">
        <li v-for="(item, index) in lyricArray" :key="index">
          <p :class="{ highlight: index == lyricIndex }">
            {{ item.lyric }}
          </p>
        </li>
      </ul>
    </div>
    <!-- 进度条 -->
    <div class="playrange">
      <span>{{ currentTime | formatTime }}</span>
      <input
        type="range"
        max="100"
        :value="(currentTime / duration) * 100"
        @change="changeTime"
      />
      <span>{{ duration | formatTime }}</span>
    </div>
    <div class="box">
      <!-- 顺序播放 -->
      <i
        class="order iconfont icon-ttpodicon"
        @click="Num()"
        v-show="num == 1"
      ></i>
      <!-- 随机播放 -->
      <i
        class="random iconfont icon-suijibofang"
        @click="Num()"
        v-show="num == 2"
      ></i>
      <!-- 单曲循环 -->
      <i
        class="single iconfont icon-danquxunhuan1"
        @click="Num()"
        v-show="num == 3"
      ></i>
      <!-- 列表循环 -->
      <i
        class="loop iconfont icon-xunhuan2"
        @click="Num()"
        v-show="num == 4"
      ></i>
      <!-- 上一曲 -->
      <i class="prev iconfont icon-shangyishou" @click="prev()"></i>
      <!-- 播放 -->
      <i
        class="play iconfont icon-player"
        v-show="!playstate"
        @click="$emit('play')"
      ></i>
      <!-- 暂停 -->
      <i
        class="pause iconfont icon-suspend"
        v-show="playstate"
        @click="$emit('pause')"
      ></i>
      <!-- 下一曲 -->
      <i class="next iconfont icon-xiayishou" @click="next()"></i>
      <!-- 播放列表 -->
      <i
        class="mylist iconfont icon-bofangliebiao"
        @click="showList = !showList"
      ></i>
      <transition
        enter-active-class="animate__animated animate__rotateInDownLeft"
        leave-active-class="animate__animated animate__rotateOutUpRight"
      >
        <ul class="playList" v-show="showList">
          <li><p>播放列表</p></li>
          <li v-for="song in playList" :key="song.id">
            <p @click="$emit('changePlayMusic', song)">
              <b v-show="playMusic.id == song.id">正在播放:</b>
              <span :class="{ con: playMusic.id == song.id }">{{
                song.name
              }}</span>
            </p>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "playMusic",
    "songs",
    "playstate",
    "lyric",
    "duration",
    "currentTime",
    "playList",
    "playMode",
  ],
  data() {
    return {
      showList: false,
      num: 3,
    };
  },
  created() {
    this.Num();
    console.log("aaa", this.num);
  },
  methods: {
    Num() {
      this.num++;
      if (this.num >= 5) {
        this.num = 1;
      }
      this.$emit("changemode", this.num);
      console.log("bbb", this.num);
    },
    next() {
      let i = 0;
      this.playList.map((d, index) => {
        if (d.id == this.playMusic.id) {
          //找到当前播放音乐下标
          i = index;
        }
      });
      if (this.playMode == 1) {
        if (i == this.playList.length - 1) {
          // this.$parent.pause();
          i = this.playList.length - 1;
          this.$parent.pause();
        } else {
          i++; //下一曲
        }
      } else if (this.playMode == 2) {
        //随机播放
        if (this.playList.length == 1) {
          this.$parent.$refs.player.play();
        } else {
          i = Math.floor(Math.random() * this.playList.length);
        }
      } else if (this.playMode == 4) {
        if (this.playList.length == 1) {
          this.$parent.$refs.player.play();
        } else if (i == this.playList.length - 1) {
          i = 0;
        } else {
          i++;
        }
      }
      let pmusic = this.playList[i];
      this.$emit("changePlayMusic", pmusic);
    },
    prev() {
      let i = 0;
      this.playList.map((d, index) => {
        if (d.id == this.playMusic.id) {
          //找到当前播放音乐下标
          i = index;
        }
      });
      if (this.playMode == 1) {
        i--; //上一曲 判断是不是第一曲
      } else if (this.playMode == 2) {
        i = Math.floor(Math.random() * this.playList.length);
      } else if (this.playMode == 4) {
        if (i == 0) {
          i = this.playList.length - 1;
        } else {
          i--;
        }
      }
      let pmusic = this.playList[i];
      this.$emit("changePlayMusic", pmusic);
      //$emit('',song)
    },
    changeTime(event) {
      let val = event.target.value;
      this.$emit("changetime", val);
      // this.$parent.playstate = false;
      let t = (val / 100) * this.$parent.duration; //得到播放时间
      this.$parent.$refs.player.currentTime = t; //改变播放时间

      console.log("changeTime==>", event.target.value);
    },
  },
  computed: {
    lyricArray() {
      //格局字符串歌词 转化为数组
      let lyric = this.lyric;
      if (!lyric) return []; //如果没有歌词返回空
      let arr = lyric.split("\n");
      arr.pop();
      let reg = /\[(\d+):(\d+\.\d+)\](.*)/;
      let lyricArr = arr.map((d) => {
        if (reg.test(d)) {
          let lyricObj = {
            time: parseInt(RegExp.$1) * 60 + parseFloat(RegExp.$2),
            lyric: RegExp.$3,
          };
          return lyricObj;
        }
      });

      return lyricArr;
    },
    lyricIndex() {
      //计算属性当前歌词下标
      let ctime = this.currentTime; //当前播放时间
      let lyric = this.lyricArray; //歌词计算属性数组
      let index = 0;
      for (let i = 0; i < lyric.length; i++) {
        if (i >= lyric.length - 1) {
          //最后一条歌词
          index = i;
          break;
        }
        if (lyric[i].time <= ctime && ctime < lyric[i + 1].time) {
          index = i;
          break;
        }
      }
      return index;
    },
  },
  filters: {
    formatTime(val) {
      let minutes = parseInt(val / 60);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let sec = parseInt(val) - parseInt(minutes * 60);
      sec = sec < 10 ? "0" + sec : sec;
      return minutes + ":" + sec;
    },
  },
};
</script>
<style lang="less" scoped>
.playpage {
  width: 100vw;
  height: 100vh;
  background-color: #394867;
  position: fixed;
  top: 0px;
  left: 0px;
  color: #d8ebe4;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    height: 80px;
    list-style: none;
    li {
      padding-top: 10px;
      i {
        display: inline-block;
        width: 40px;
        text-align: center;
        font-size: 30px;
      }
      &:nth-of-type(2) {
        flex: 1;
        line-height: 40px;
        p {
          text-align: center;
          font-size: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-overflow: -o-ellipsis-lastline;
          display: -webkit-box;
          -webkit-line-clamp: 1; //行数需设置
          -webkit-box-orient: vertical;
          &:nth-of-type(2) {
            line-height: 31px;
            font-size: 16px;
            // padding: 5px 20px;
          }
        }
      }
      &:nth-of-type(3) {
        i {
          width: 50px;
          font-size: 35px;
        }
      }
    }
  }
  .img {
    flex: 1;
    img {
      width: 30vh;
      height: 30vh;
      margin: 0 auto;
      border-radius: 50%;
      border: 10px solid rgba(84, 81, 78, 0.5);
      animation: spin 10s 0s linear infinite;
    }
    .paus {
      animation-play-state: paused;
    }
  }
  .lyricbox {
    height: 40vh;
    text-align: center;
    padding: 0 80px;
    overflow: hidden;
    margin: 22px 0;
    li {
      overflow: hidden;
      padding: 5px 0;
      p {
        font-size: 16px;
        // white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: -o-ellipsis-lastline;
        display: -webkit-box;
        -webkit-line-clamp: 1; //行数需设置
        -webkit-box-orient: vertical;
        &.highlight {
          color: white;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
  .playrange {
    display: flex;
    span {
      color: #d8ebe4;
      text-align: center;
      padding: 0 10px;
    }
    input {
      flex: 1;
    }
  }
  .box {
    display: flex;
    justify-content: space-around;
    height: 80px;
    i {
      text-align: center;
      line-height: 80px;
      font-size: 28px;
      text-align: center;
      display: flex;
      &.play,
      &.pause {
        font-size: 55px;
        padding: 0 5px;
        position: relative;
        top: -2px;
      }
      &.prev,
      &.next {
        font-size: 50px;
        position: relative;
        top: -5px;
      }
      &.loop,
      &.single,
      &.random {
        padding: 0 5px 0 20px;
      }
      &.order {
        font-size: 40px;
        padding: 0 5px 0 20px;
      }
      &.mylist {
        padding: 0 20px 0 5px;
      }
    }
    .playList {
      position: fixed;
      // right: 0px;
      bottom: 105px;
      width: 40vh;
      margin: 0 auto;
      // background-color: rgba(0, 117, 128, 0.9);
      background-color: rgba(57, 72, 103, 0.8);
      border: 1px solid #fafafa;
      border-top: 2px solid #fafafa;
      // border-right: none;
      // border-radius: 10px 0 0 10px;
      border-radius: 10px;
      padding: 20px 0;
      li {
        overflow: hidden;
        p {
          color: #f1f6f9;
          text-align: center;
          width: 100%;
          padding: 0 10px;
          height: 30px;
          line-height: 30px;
          b {
            // color: #193549;
            font-size: 20px;
          }
          span {
            font-size: 16px;
            white-space: normal;
            padding: 0 10px;
            border-radius: 5px;
            // background-color: rgba(216, 235, 228, 0.5);
            &.con {
              // color: #193549;
              font-weight: bold;
              font-size: 17px;
            }
          }
        }
        &:first-child {
          font-size: 20px;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid #fafafa;
          p {
            // text-align: center;
            // color: #282846;
            padding: 0 50px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>