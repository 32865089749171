<template>
  <div class="navbar">
    <div class="top">
      <span
        class="iconfont icon-arrow-left-bold"
        @click="back()"
        v-show="opens == !$route.meta.hideNav"
      >
        <b v-show="$route.meta.gedan == !$route.meta.hideNav">歌单</b>
        <b v-show="$route.meta.geshou == !$route.meta.hideNav">歌手</b>
      </span>
      <span></span>
      <router-link
        class="iconfont icon-search"
        v-show="!$route.meta.hideNav || opens == !$route.meta.playpage || $route.meta.gedan == !$route.meta.hideNav"
        tag="span"
        to="/search"
      ></router-link>
    </div>
    <ul v-show="!$route.meta.hideNav">
      <router-link tag="li" to="/home">首页</router-link>
      <router-link tag="li" to="/ranking">热歌榜</router-link>
      <router-link tag="li" to="/singer">歌手</router-link>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      opens: false,
    };
  },
  methods: {
    back() {
      // this.$router.push('/home/recommend');
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.navbar {
  .top {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    filter: opacity(95%);
    // background-color: #14274e ;
    border-bottom: 1px solid #e6e6e6;
    background-color: #f1f6f9 ;
    background: url("../assets/img/wsry.png") no-repeat center center;
    // background: url("../assets/img/logo.jpg") no-repeat center center;
    span {
      font-size: 26px;
      font-weight: bold;
      color:#14274e;
      &:nth-of-type(3) {
        padding-right: 10px;
      }
      b {
        padding-left: 5px;
        font-size: 26px;
      }
    }
  }
  ul {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    background-color: #fafafa;
    li {
      flex: 1;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      border-radius: 5px 5px 0 0;
      color: #282846;
      &.router-link-home-active {
        background-color: white;
        color: #282846;
        font-weight: bolder;
        border-bottom: 2px solid #394867;
      }
    }
  }
}
</style>