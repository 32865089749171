<template>
  <div id="app">
    <div class="topNull"></div>
    <nav-bar class="nav"></nav-bar>
    <!-- mini播放器 -->
    <mini-player
      class="mini animate__animated"
      v-if="playMusic"
      :playMusic="playMusic"
      :playList="playList"
      @changePlayMusic="changePlayMusic"
    ></mini-player>
    <!-- <keep-alive> -->
    <!-- <transition
      enter-active-class="animate__animated animate__slideInLeft"
      leave-active-class="animate__animated animate__slideOutRight"
    > -->
      <router-view @play="play" />
    <!-- </transition> -->
    <div v-if="playMusic" class="botNull"></div>
    <!-- </keep-alive> -->
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import MiniPlayer from "@/components/MiniPlayer";
import defaultPic from "@/assets/img/defaultPic.jpg";
export default {
  data() {
    return {
      show: true,
      playMusic: null,
      playList: [],
    };
  },
  components: {
    NavBar,
    MiniPlayer,
  },
  methods: {
    changePlayMusic(event) {
      if (event) {
        this.playMusic = event;
      }
    },
    play(event) {
      // 判断歌曲对象里面是否有图片 没有就拿本地的图片.
      if (!event.al) {
        event.al = defaultPic;
      }
      this.playMusic = event;
      console.log("vue app=>", event);
      //加入歌单
      let isPush = true;
      this.playList.map((d) => {
        if (d.id == event.id) {
          //歌曲已经存在
          isPush = false;
        }
      });
      if (isPush) this.playList.push(event);
    },
  },
};
</script>
<style lang="less">
#app {
  .topNull {
    height: 50px;
  }
  .botNull {
    height: 50px;
  }
  .nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
  }
  .mini {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 50px;
    display: flex;
    z-index: 9;
    // background-color: rgba(57, 72, 103, 0.9);
    background-color: #f1f6f9;
  }
}
</style>
