<template>
  <div class="miniplay">
    <!-- <h1>mini播放器</h1> -->
    <!-- onended="play()" -->
    <audio
      @timeupdate="timeupdate"
      @ended="end"
      @changeTime="changeTime()"
      style="width: 1000px"
      autoplay
      :src="songUrl.url"
      ref="player"
    ></audio>
    <div class="playerbox">
      <div
        class="img"
        v-for="(pic, index) in songs.al"
        :key="index"
        v-show="index == 'picUrl'"
      >
        <img
          :class="[{ paus: !playstate }]"
          :src="index == 'picUrl' ? pic : ''"
        />
      </div>
      <div class="content" @click="opens = true">
        <p>{{ songs.name }}</p>
        <p>
          <span v-for="item in songs.ar" :key="item.id">
            {{ item.name }} -
          </span>
          <span v-for="(i, index) in songs.al" :key="index">
            {{ index == "name" ? i : "" }}</span
          >
        </p>
      </div>
      <div class="box">
        <i
          class="play iconfont icon-player"
          v-show="!playstate"
          @click.stop="play()"
        ></i>
        <i
          class="pause iconfont icon-suspend"
          v-show="playstate"
          @click.stop="pause()"
        ></i>
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__bounceInUp"
      leave-active-class="animate__animated animate__bounceOutDown"
    >
      <play-page
        v-if="opens"
        ref="fullplay"
        :playMusic="playMusic"
        :songs="songs"
        :lyric="lyric"
        :playstate="playstate"
        :duration="duration"
        :currentTime="currentTime"
        :playList="playList"
        :playMode="playMode"
        @changemode="playMode = $event"
        @changeTime="changeTime"
        @close="opens = false"
        @play="play"
        @pause="pause"
        @changePlayMusic="$emit('changePlayMusic', $event)"
      ></play-page>
    </transition>
  </div>
</template>
<script>
import PlayPage from "@/components/PlayPage";
export default {
  props: ["playMusic", "playList"],
  data() {
    return {
      songUrl: [],
      songs: [],
      lyric: [],
      opens: false,
      playstate: true, //记录是否播放
      duration: 0,
      currentTime: 0,
      playMode: 1, //1 顺序播放 2.随机播放 3.单曲循环 4.列表循环
    };
  },
  components: {
    PlayPage,
  },
  methods: {
    end() {
      console.log("enddddddddd");
      if (this.playMode == 3) {
        //如果播放模式 是单曲循环 继续播放当前歌曲
        this.$refs.player.play();
      } else {
        //当前音乐播放完成 播放下一曲 调用子组件的next函数.
        this.$refs.fullplay.next();
      }
    },
    play() {
      this.$refs.player.play(); //播放
      this.playstate = true;
      console.log("开");
    },
    pause() {
      this.$refs.player.pause(); //暂停
      this.playstate = false;
      console.log("关");
    },
    changeTime(val) {
      let t = (val / 100) * this.duration; //得到播放时间
      // console.log("Mini===>", val);
      this.$refs.player.currentTime = t; //改变播放时间
    },
    timeupdate(event) {
      this.duration = event.target.duration; //音乐总时长
      this.currentTime = event.target.currentTime; //当前播放时间.
      // this.drawCircle(this.duration, this.currentTime);
    },
  },
  created() {
    //获取歌曲详情
    this.$axios.get(`/song/detail?ids=${this.playMusic.id}`).then((s) => {
      this.songs = s.data.songs[0];
      console.log("song111", this.songs);
    });
    //获取歌曲url
    this.$axios.get(`/song/url?id=${this.playMusic.id}`).then((d) => {
      this.songUrl = d.data.data[0];
      console.log("歌曲URL111", d);
    });
    this.$nextTick(() => {
      this.$refs.player.play();
    });
    //获取歌词
    this.$axios.get(`/lyric?id=${this.playMusic.id}`).then((l) => {
      this.lyric = l.data.lrc.lyric;
      console.log("歌词111", l);
    });
  },
  watch: {
    playMusic: {
      deep: true,
      handler(obj) {
        // console.log("123", obj);
        this.$axios.get(`/song/detail?ids=${obj.id}`).then((s) => {
          this.songs = s.data.songs[0];
          console.log("song222", this.songs);
        });
        this.$axios.get(`/song/url?id=${obj.id}`).then((d) => {
          this.songUrl = d.data.data[0];
          console.log("歌曲URL222", d);
        });
        this.$axios.get(`/lyric?id=${obj.id}`).then((l) => {
          this.lyric = l.data.lrc.lyric;
          console.log("歌词222", this.lyric);
        });
        this.$nextTick(() => {
          this.playstate = true;
          this.$refs.player.play();
        });
      },
    },
  },
};
</script>
<style lang="less" scoped>
.miniplay {
  .playerbox {
    width: 100%;
    display: flex;
    line-height: 50px;
    overflow: hidden;
    // color: #d8ebe4;
    color: #14274e;
    .img {
      padding-left: 10px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 3px;
        background-color: #f1f6f9;
        animation: spin 10s 0s linear infinite;
      }
      .paus {
        animation-play-state: paused;
      }
    }
    .content {
      flex: 1;
      height: 50px;
      padding: 2px 0;
      p {
        font-size: 16px;
        line-height: 25px;
        text-align: left;
        padding-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: -o-ellipsis-lastline;
        display: -webkit-box;
        -webkit-line-clamp: 1; //行数需设置
        -webkit-box-orient: vertical;
        &:nth-of-type(2) {
          font-size: 14px;
        }
      }
    }
    .box {
      // width: 1%;
      i {
        font-size: 40px;
        padding: 0 15px 0 10px;
      }
    }
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

