<template>
  <router-link
    tag="li"
    :to="{
      name: 'Musiclist',
      params: { id: item.id },
    }"
    class="dt"
  >
    <img :src="item.picUrl" />
    <p>{{ item.name }}</p>
  </router-link>
</template>
<script>
export default {
  props: {
    item: Object,
  },
};
</script>
<style lang="less" scoped>
.dt {
  img {
    border-radius: 20px;
  }
  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 30px;
    font-size: 13px;
    padding-top: 10px;
    margin-bottom: 10px;
  }
}
</style>