<template>
  <div class="recommend">
    <div class="top">
      <h2>每日编辑推荐</h2>
      <span @click="change()">换一换<i class="iconfont icon-refresh"></i></span>
    </div>

    <ul class="multiplexing">
      <song-sheet v-for="item in newSong" :key="item.id" :item="item">
      </song-sheet>
    </ul>

    <div class="top">
      <h2>宝藏MV</h2>
      <!-- <span>换一换<i class="iconfont icon-refresh"></i></span> -->
    </div>
    <ul class="mvlist">
      <song-sheet v-for="item in mv" :key="item.id" :item="item"></song-sheet>
    </ul>

    <div class="top">
      <h2>这些电台，正合你意</h2>
      <!-- <span>换一换<i class="iconfont icon-refresh"></i></span> -->
    </div>
    <ul class="dtlist">
      <radio-station
        v-for="item in dt"
        :key="item.id"
        :item="item"
      ></radio-station>
    </ul>
    <h1>暂无更多歌单</h1>
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src
import SongSheet from "@/components/SongSheet.vue";
import RadioStation from "@/components/RadioStation.vue";
export default {
  components: {
    SongSheet,
    RadioStation,
  },
  data() {
    return {
      song: [],
      mv: [],
      dt: [],
      newSong: [],
      //截取每一页的开始数
      start: 0,
      //截取每一页的结束数
      end: 1,
      //数据下的平均到的组数
      group: 0,
      //截取展示个数 默认6个
      num: 6,
      //点击一次 记录一次
      clickNum: 0,
    };
  },
  methods: {
    change() {
      console.log("换一换");
      //调用组数
      this.listlen();
      this.clickRecord();
      this.clear();
      this.newSlice();
      console.log(this.newSong);
    },
    //获取数据中的组数
    listlen() {
      //判断是否平均分组
      if (this.song.length % this.num != 0) {
        this.group = parseInt(this.song.length / this.num) + 1;
      } else {
        this.group = this.song.length / this.num;
      }
      console.log(this.group);
    },
    //判断 当点击次数 == 平均分组数 时 回到默认开头
    clear() {
      if (this.clickNum == this.group - 1) {
        this.clickNum = 0;
        this.start = 0;
        this.end = 1;
      }
    },
    //每点击一次 记录一次
    clickRecord() {
      this.clickNum++;
      this.start++;
      this.end++;
      console.log(this.clickNum, this.start, this.end);
    },
    //将获取的数据 进行截取
    newSlice() {
      this.newSong = this.song.slice(
        //每一页的开始数
        this.num * this.start,
        //每一页的结束数
        this.num * this.end
      );
      // console.log(this.newSong);
    },
  },
  created() {
    //推荐歌单
    this.$axios.get("/personalized?limit=100").then((d) => {
      this.song = d.data.result;
      // this.song = this.data.slice(0,6);
      console.log(d);
      //调用截取长度个数函数
      this.newSlice();
    });
    //推荐MV
    this.$axios.get("/personalized/mv").then((m) => {
      this.mv = m.data.result;
      console.log(this.mv);
    });
    //推荐电台
    this.$axios.get("/personalized/djprogram").then((t) => {
      this.dt = t.data.result;
      console.log(this.dt);
    });
  },
};
</script>
<style lang="less" scoped>
.recommend {
  // background-color: #d8ebe4;
  .top {
    overflow: hidden;
    h2 {
      font-size: 15px;
      // font-weight: bolder;
      margin-bottom: 10px;
      color: #f0f0f0;
      padding: 5px 10px;
      border-radius: 10px 0 10px;
      background-color: #485c84;
      float: left;
    }
    span {
      float: right;
      color: #394867;
      font-size: 15px;
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
      font-weight: bold;
      i {
        padding-left: 5px;
      }
    }
  }
  .multiplexing {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 32%;
      position: relative;
    }
  }
  .mvlist {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 49%;
      position: relative;
    }
  }
  .dtlist {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 49%;
      position: relative;
    }
  }
}
</style>