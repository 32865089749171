<template>
  <router-link
    v-show="!$route.meta.aaa"
    tag="li"
    :to="{
      name: 'Musiclist',
      params: { id: item.id },
    }"
    class="SongSheet"
  >
    <img :src="item.picUrl" />
    <p>{{ item.name }}</p>
    <div class="hits">
      <i class="iconfont icon-erji"></i>
      <span>{{ item.playCount | formatNum }}</span>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {};
  },
  filters: {
    formatNum(value) {
      if (value >= 100000000) {
        // return (parseInt(value) / 100000000).toFixed(3) + "亿";
        return (parseInt(value) / 100000000).toFixed(1) + "亿";
      } else if (value >= 100000 && value < 100000000) {
        // return (parseInt(value) / 10000).toFixed(2) + "万";
        return (parseInt(value) / 10000).toFixed(0) + "万";
      } else if (value >= 10000 && value < 100000) {
        // return (parseInt(value) / 10000).toFixed(3) + "万";
        return (parseInt(value) / 10000).toFixed(0) + "万";
      } else {
        return value;
      }
    },
  },
};
</script>
<style lang="less" scoped>
img {
  // border: 1px solid #bedbbb;
  border-top: 3px solid #bedbbb;
  border-radius: 20px;
}
p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 30px;
  font-size: 13px;
  padding-top: 10px;
  margin-bottom: 10px;
}
.hits {
  padding: 0px 10px;
  position: absolute;
  top: 6px;
  right: 5px;
  border-radius: 20px;
  background-color: rgba(245, 246, 247, 0.7);
  display: flex;
  height: 22px;
  line-height: 22px;
  i {
    font-style: normal;
    font-size: 14px;
  }
  span {
    padding-left: 3px;
    color: #282846;
    font-size: 13px;
  }
}
</style>