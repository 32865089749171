import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Recommend from '@/views/Homelist/Recommend.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    meta:{
      hideNav:false,
    },
    component: Home,
    children:[
      {
        path: '/home',
        redirect: '/home/recommend'
      },
      {
        path: '/home/recommend',
        name: 'Recommend',
        component: Recommend,
      },
      {
        path: '/home/hot',
        name: 'Hot',
        component: () => import('@/views/Homelist/Hot.vue')
      },
      {
        path: '/home/new',
        name: 'New',
        component: () => import('@/views/Homelist/New.vue')
      },
      {
        path: '/home/chinese',
        name: 'Chinese',
        component: () => import('@/views/Homelist/Chinese.vue')
      },
      {
        path: '/home/popular',
        name: 'Popular',
        component: () => import('@/views/Homelist/Popular.vue')
      },
      {
        path: '/home/ancient',
        name: 'Ancient',
        component: () => import('@/views/Homelist/Ancient.vue')
      },
      {
        path: '/home/western',
        name: 'Western',
        component: () => import('@/views/Homelist/Western.vue')
      },
    ]
  },
  {
    path: '/search',
    name: 'Search',
    meta:{
      hideNav:true,
    },
    component: () => import('@/views/Search.vue')
  },
  {
    path: '/ranking',
    name: 'Ranking',
    meta:{
      hideNav:false,
    },
    component: () => import('@/views/Ranking.vue')
  },
  {
    path: '/singer',
    name: 'Singer',
    children:[
      {
        path: '/singer/singerpage/:id/:picUrl',
        name: 'Singerpage',
        props:true,
        meta:{
          singer:true,
          hideNav:true,
          geshou:false,
        },
        component: () => import('@/components/SingerPage.vue')
      }
      
    ],
    meta:{
      hideNav:false,
      singer:false,
    },
    component: () => import('@/views/Singer.vue')
  },
  {
    path: '/home/recommend/musiclist/:id',
    name: 'Musiclist',
    props: true,
    meta:{
      hideNav:true,
      gedan:false,
      playpage:false,
    },
    component: () => import('@/components/Recommend/MusicList.vue')
  },
  {
    path: '*',
    meta:{
      hideNav:true,
    },
    component: () => import('@/components/404.vue')
  }
]

const router = new VueRouter({
  routes,
  linkActiveClass:"router-link-home-active",
})

export default router
